<template>
  <v-container fluid>
    <div 
      contenteditable="true"
      ref="editor"
      @input="oninput"
      class="editor"
      v-html="content"
      ></div>
      {{content}}
  </v-container>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import formatter from '@/mixins/formatter';

function setCaret(node, offset) {
    const range = document.createRange()
    const sel = window.getSelection()
    range.setStart(node, offset)
    range.collapse(true)
    sel.removeAllRanges()
    sel.addRange(range)
}


@Component({ mixins: [formatter] })
export default class Editor extends Vue {

  editor = null;
  content = '(<b>{</b>fs:1} + {fs:2}) / {agg:2}';

  mounted() {
    this.editor = this.$refs.editor;
  }

  oninput() {
    // this.content = this.editor.innerHTML;
    setCaret(this.editor, 3);
  }
}
</script>


<style scoped>
.editor {
  font-family: monospace;
  padding: 0.5em;
  background: #fff;
  color: black;
}
</style>